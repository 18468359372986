import {
  useEffect,
  useRef,
  useState,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';

import {
  BasicMediaCarousel,
  type BasicCarouselHandle,
  type BasicMediaCarouselProps,
  type ControlsProps,
} from './BasicMediaCarousel';
import {
  DefaultMediaCarouselControls,
  type DefaultMediaCarouselControlsProps,
} from './components/DefaultMediaCarouseControls/DefaultMediaCarouselControls';

export interface MediaCarouselProps
  extends BasicMediaCarouselProps,
    Omit<DefaultMediaCarouselControlsProps, keyof ControlsProps>,
    PropsWithChildren {
  /**
   * Number of milliseconds before automatic rotation of slide
   * When the media is hovered, the period is reset
   */
  autoplayInterval?: number;
}

export const MediaCarousel: FunctionComponent<MediaCarouselProps> = ({
  alwaysShowButtonsOnDesktop = false,
  autoplayInterval,
  children,
  disableIndicators = false,
  indicatorClassName,
  indicatorIcon: IndicatorIcon,
  indicatorType = 'dots',
  smallControls = false,
  ...otherProps
}) => {
  const [modeAutoplay, setModeAutoplay] = useState(!!autoplayInterval);
  const carousel = useRef<BasicCarouselHandle>(null);

  const onHoverSlide = (state: boolean) => {
    if (autoplayInterval) {
      setModeAutoplay(!state);
    }
  };

  useEffect(() => {
    let id: number;
    if (modeAutoplay) {
      id = window.setInterval(() => {
        if (carousel.current) carousel.current.onNextSlide();
      }, autoplayInterval);
    }
    return () => window.clearTimeout(id);
  }, [autoplayInterval, modeAutoplay]);

  return (
    <BasicMediaCarousel
      {...otherProps}
      ref={carousel}
      onHoverSlide={onHoverSlide}
      controls={(props) => (
        <DefaultMediaCarouselControls
          {...props}
          disableIndicators={disableIndicators}
          indicatorClassName={indicatorClassName}
          indicatorType={indicatorType}
          indicatorIcon={IndicatorIcon}
          alwaysShowButtonsOnDesktop={alwaysShowButtonsOnDesktop}
          smallControls={smallControls}
        />
      )}
    >
      {children}
    </BasicMediaCarousel>
  );
};

export default MediaCarousel;
