import type { Channel } from 'redux-saga';
import { actionChannel, delay, take, takeEvery } from 'redux-saga/effects';

import { TRACKING_DONE } from '@/core/tracking/tracking.actions';
import { Gtm } from '@/core/tracking/utils/Gtm';
import {
  DISPLAY_CAMPAIGN_IMAGE,
  type DisplayCampaignImage,
} from '@/productDiscovery/CommercialAnimation/components/CampaignImage/CampaignImage.actions';
import { GTM_DISPLAY_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/displayBannerCampaign';

const TRACKING_DELAY: { [key: string]: number } = {
  secondary_0: 800,
  secondary_1: 1500,
  tertiary_0: 800,
  tertiary_1: 1500,
};

// eslint-disable-next-line require-yield
function* sendTrackingForCampaignImageSaga(action: DisplayCampaignImage) {
  const trackingDelay =
    TRACKING_DELAY[`${action.data.type}_${action.data.position}`] || 200;
  yield delay(trackingDelay);

  Gtm.push(
    GTM_DISPLAY_BANNER_CAMPAIGN(
      action.data.type === 'main'
        ? 'slider_banner'
        : `${action.data.type}_banner`,
      action.data.bannerCampaignId,
      action.data.bannerCampaignName,
      action.data.bannerCampaignLinkUrl,
      action.data.position,
      action.data.hasSponsoredLabel,
      action.data.bannerCampaignPageType,
      action.data.bannerCampaignTrackingId,
    ),
  );
}

export function* campaignImageTrackingSaga(): Generator<any> {
  const displayCampaignImageChannel = yield actionChannel(
    DISPLAY_CAMPAIGN_IMAGE,
  );
  yield take(TRACKING_DONE);
  yield takeEvery(
    displayCampaignImageChannel as Channel<DisplayCampaignImage>,
    sendTrackingForCampaignImageSaga,
  );
}
