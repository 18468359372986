import classnames from 'classnames';
import {
  Children,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  type ComponentType,
  type KeyboardEvent,
  type MouseEvent,
  type PropsWithChildren,
  type ReactElement,
} from 'react';

import {
  Slider,
  type SliderHandle,
} from '../Carousel/components/Slider/Slider';

import styles from './BasicMediaCarousel.module.scss';

export interface BasicCarouselHandle {
  /**
   * function called to go to the <index> slider (0 based)
   * @param index The index to go to
   */
  onChangeIndex: (index: number) => void;
  /**
   * function called to go to the next slide
   */
  onNextSlide: () => void;
  /**
   * function called to go to the previous slide
   */
  onPrevSlide: () => void;
}

export interface ControlsProps extends BasicCarouselHandle {
  /**
   * true the next button should be disabled
   */
  isNextDisabled: boolean;
  /**
   * true if the prev button should be disabled
   */
  isPrevDisabled: boolean;
  /**
   * The number of slides
   */
  numberOfSlides: number;
  /**
   * the state of the carousel
   */
  scrollState: {
    index: number;
    position: number;
    min: number;
    max: number;
  };
}

export interface ControlIndexes {
  controlsName: string;
  nextIndex: number;
  previousIndex: number;
}

export interface BasicMediaCarouselProps extends PropsWithChildren {
  /**
   * Root element className
   */
  className?: string;
  /**
   * A component to replace the controls by your owns
   */
  controls?: ComponentType<ControlsProps>;
  /**
   * When disableWrap is true,
   * - left button is disabled on first slide
   * - right button is disabled on last slide
   */
  disableWrap?: boolean;
  /**
   * Scroll the carousel to the element at that position (0 based).
   */
  initialScrollIndex?: number;
  /**
   * Used to intercept clicks on any controls like dots or arrows
   * (ie: tracking, action dispatching…)
   * ⚠️ this does not trigger when the user scrolls, you should use onSlideChange for that
   */
  onControlClick?: (indexes: ControlIndexes) => void;
  /**
   * Triggered when the user hover the carousel
   * @param isHover True if the user cursor is over the carousel, false otherwise
   */
  onHoverSlide?: (isHover: boolean) => void;
  /**
   * Used to intercept slides change either by navigating or by scrolling.
   * This is not triggered by the initialScrollIndex
   */
  onSlideChange?: (currentPosition: number, previousPosition: number) => void;
}

export const BasicMediaCarousel = forwardRef<
  BasicCarouselHandle,
  BasicMediaCarouselProps
>(
  (
    {
      children,
      className,
      controls: CustomControls,
      disableWrap = false,
      initialScrollIndex = 0,
      onControlClick,
      onHoverSlide = () => {},
      onSlideChange,
    },
    forwardedRef,
  ) => {
    const [scrollState, setScrollState] = useState({
      index: 0,
      position: 0,
      min: 0,
      max: 1,
    });
    const containerRef = useRef(null);
    const previousIndexRef = useRef(0);
    const waitForInitialScrollRef = useRef(!!initialScrollIndex);
    const slides = Children.toArray(children);
    const slider = useRef<SliderHandle>(null);
    const isSingleSlide = slides.length === 1;
    const carouselClassnames = classnames(styles.carousel, className);
    const onSwitchSlide = (index: number) => {
      slider?.current?.scrollToIndex(index);
    };

    const onPrevSlide = () => {
      if (scrollState.index < 1) {
        if (!disableWrap) {
          onSwitchSlide(slides.length - 1);
          if (onControlClick) {
            onControlClick({
              previousIndex: scrollState.index,
              nextIndex: slides.length - 1,
              controlsName: 'click on previous slider',
            });
          }
        }
      } else {
        onSwitchSlide(scrollState.index - 1);
        if (onControlClick) {
          onControlClick({
            previousIndex: scrollState.index,
            nextIndex: scrollState.index - 1,
            controlsName: disableWrap
              ? 'swipe on previous slider'
              : 'click on previous slider',
          });
        }
      }
    };

    const onNextSlide = (
      event: KeyboardEvent | MouseEvent | undefined = undefined,
    ) => {
      if (scrollState.index === slides.length - 1) {
        if (!disableWrap) {
          onSwitchSlide(0);
          if (event && onControlClick) {
            onControlClick({
              previousIndex: scrollState.index,
              nextIndex: 0,
              controlsName: 'click on next slider',
            });
          }
        }
      } else {
        onSwitchSlide(scrollState.index + 1);
        if (event && onControlClick) {
          onControlClick({
            previousIndex: scrollState.index,
            nextIndex: scrollState.index + 1,
            controlsName: disableWrap
              ? 'swipe on next slider'
              : 'click on next slider',
          });
        }
      }
    };

    const onChangeIndex = (index: number) => {
      onSwitchSlide(index);
      if (onControlClick) {
        onControlClick({
          previousIndex: scrollState.index,
          nextIndex: index,
          controlsName: 'click on dots slider',
        });
      }
    };

    useEffect(() => {
      if (initialScrollIndex > 0 && slider && slider.current) {
        slider.current.scrollToIndex(initialScrollIndex, false);
      }
    }, [initialScrollIndex]);

    useEffect(() => {
      if (
        onSlideChange &&
        scrollState.index !== previousIndexRef.current &&
        !waitForInitialScrollRef.current
      ) {
        onSlideChange(scrollState.index, previousIndexRef.current);
      }
      previousIndexRef.current = scrollState.index;

      if (
        waitForInitialScrollRef.current &&
        scrollState.index === initialScrollIndex
      ) {
        waitForInitialScrollRef.current = false;
      }
    }, [initialScrollIndex, onSlideChange, scrollState.index]);

    useImperativeHandle(forwardedRef, () => ({
      onPrevSlide,
      onNextSlide,
      onChangeIndex,
    }));

    let displayedControls: ReactElement<ControlsProps> | null = null;
    if (!isSingleSlide) {
      const isPrevDisabled = disableWrap && scrollState.position === 0;
      const isNextDisabled =
        disableWrap &&
        scrollState.max > 0 &&
        scrollState.position >= scrollState.max;
      if (CustomControls) {
        displayedControls = (
          <CustomControls
            onPrevSlide={onPrevSlide}
            onNextSlide={onNextSlide}
            onChangeIndex={onChangeIndex}
            isNextDisabled={isNextDisabled}
            isPrevDisabled={isPrevDisabled}
            scrollState={scrollState}
            numberOfSlides={slides.length}
          />
        );
      }
    }

    return (
      <div
        className={carouselClassnames}
        ref={containerRef}
        onMouseEnter={() => onHoverSlide(true)}
        onMouseLeave={() => onHoverSlide(false)}
      >
        <Slider
          ref={slider}
          className={styles.container}
          onScrollChange={setScrollState}
        >
          {children}
        </Slider>
        {displayedControls}
      </div>
    );
  },
);
