import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useState, type FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';

import { Image } from '@/core/Image/Image';
import { Link } from '@/core/routing/components/Link';
import { useTrackViewedElement } from '@/core/tracking/hooks/useTrackViewedElement';
import { Gtm } from '@/core/tracking/utils/Gtm';
import { displayCampaignImage } from '@/productDiscovery/CommercialAnimation/components/CampaignImage/CampaignImage.actions';
import type { CampaignModalProps } from '@/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal';
import type { BannerCampaign } from '@/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { GTM_CLICK_ON_BANNER_CAMPAIGN } from '@/productDiscovery/CommercialAnimation/tracking/events/clickOnBannerCampaign';

import styles from '@/productDiscovery/CommercialAnimation/components/CampaignImage/CampaignImage.module.scss';

const DynamicModal = dynamic<CampaignModalProps>(
  async () =>
    (
      await import(
        '@/productDiscovery/CommercialAnimation/components/CampaignModal/CampaignModal'
      )
    ).CampaignModal,
);

interface Size {
  height: number;
  width: number;
}

interface CampaignImageProps {
  sizes: {
    small: { ldpi: Size; hdpi: Size };
    medium: { ldpi: Size; hdpi: Size };
    large: { ldpi: Size; hdpi: Size };
  };
  loading?: 'eager' | 'lazy';
  altText: string;
  imageMobileUrl?: string | null;
  imageDesktopUrl?: string | null;
  imgClassName?: string;
  dataTestId?: string;
  link: {
    url: string | null;
    target: '_blank' | '_modal' | '_self';
  };
  linkClassName?: string;
  type: string;
  bannerCampaign: BannerCampaign;
  position: number;
}

export const CampaignImage: FunctionComponent<CampaignImageProps> = ({
  loading,
  altText,
  imageDesktopUrl,
  imageMobileUrl,
  sizes,
  imgClassName,
  dataTestId,
  link,
  linkClassName,
  type,
  bannerCampaign,
  position,
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  const dispatch = useDispatch();

  const [campaignRef] = useTrackViewedElement(
    () => {
      dispatch(
        displayCampaignImage({
          type,
          bannerCampaignId: bannerCampaign.id,
          bannerCampaignName: bannerCampaign.name,
          bannerCampaignLinkUrl: bannerCampaign.link.url || '',
          bannerCampaignPageType: bannerCampaign.pageType || undefined,
          bannerCampaignTrackingId: bannerCampaign.trackingId || undefined,
          position,
          hasSponsoredLabel:
            bannerCampaign.sponsor !== null &&
            bannerCampaign.advertiser !== null,
        }),
      );
    },
    { triggerOnce: true },
  );

  const trackClick = () => {
    Gtm.push(
      GTM_CLICK_ON_BANNER_CAMPAIGN(
        `${type}_banner`,
        bannerCampaign.id,
        bannerCampaign.name,
        bannerCampaign.link.url || '',
        position,
        !!(bannerCampaign.sponsor && bannerCampaign.advertiser),
        bannerCampaign.pageType || undefined,
        bannerCampaign.trackingId || undefined,
      ),
    );
  };

  const handleClick = () => {
    setDisplayModal(true);
    trackClick();
  };

  const imgElt = (
    <Image
      width={sizes.small.ldpi.width}
      height={sizes.small.ldpi.height}
      loading={loading || 'lazy'}
      alt={altText}
      src={`${imageMobileUrl}?w=${sizes.small.ldpi.width}&h=${sizes.small.ldpi.height}&fit=cover`}
      srcSet={`${imageMobileUrl}?w=${sizes.small.ldpi.width}&h=${sizes.small.ldpi.height}&fit=cover 1x, ${imageMobileUrl}?w=${sizes.small.hdpi.width}&h=${sizes.small.hdpi.height}&fit=cover 2x`}
      className={imgClassName}
      data-testid={dataTestId}
    >
      <source
        srcSet={`${imageDesktopUrl}?w=${sizes.large.ldpi.width}&h=${sizes.large.ldpi.height}&fit=cover&format=webp 1x, ${imageDesktopUrl}?w=${sizes.large.hdpi.width}&h=${sizes.large.hdpi.height}&fit=cover&format=webp 2x`}
        media="(min-width: 1440px)"
        type="image/webp"
      />
      <source
        srcSet={`${imageDesktopUrl}?w=${sizes.large.ldpi.width}&h=${sizes.large.ldpi.height}&fit=cover 1x, ${imageDesktopUrl}?w=${sizes.large.hdpi.width}&h=${sizes.large.hdpi.height}&fit=cover 2x`}
        media="(min-width: 1440px)"
      />
      <source
        srcSet={`${imageDesktopUrl}?w=${sizes.medium.ldpi.width}&h=${sizes.medium.ldpi.height}&fit=cover&format=webp 1x, ${imageDesktopUrl}?w=${sizes.medium.hdpi.width}&h=${sizes.medium.hdpi.height}&fit=cover&format=webp 2x`}
        media="(min-width: 1024px)"
        type="image/webp"
      />
      <source
        srcSet={`${imageDesktopUrl}?w=${sizes.medium.ldpi.width}&h=${sizes.medium.ldpi.height}&fit=cover 1x, ${imageDesktopUrl}?w=${sizes.medium.hdpi.width}&h=${sizes.medium.hdpi.height}&fit=cover 2x`}
        media="(min-width: 1024px)"
      />
      <source
        srcSet={`${imageMobileUrl}?w=${sizes.small.ldpi.width}&h=${sizes.small.ldpi.height}&fit=cover&format=webp 1x, ${imageMobileUrl}?w=${sizes.small.hdpi.width}&h=${sizes.small.hdpi.height}&fit=cover&format=webp 2x`}
        type="image/webp"
      />
    </Image>
  );

  if (!link.url) {
    return (
      <span className={linkClassName} data-testid="no-link" ref={campaignRef}>
        {imgElt}
      </span>
    );
  }

  return (
    <div ref={campaignRef}>
      {link.target !== '_modal' ? (
        <Link
          data-testid="link"
          href={link.url}
          target={link.target || '_self'}
          className={linkClassName}
          onClick={trackClick}
        >
          {imgElt}
        </Link>
      ) : (
        <>
          <button
            data-testid="link-modal"
            className={classnames(styles.itemLink, linkClassName)}
            onClick={handleClick}
            type="button"
          >
            {imgElt}
          </button>
          {displayModal && (
            <DynamicModal
              url={link.url}
              onClose={() => setDisplayModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
};
