import { getPlatform, isB2B } from '@/core/settings/utils';

export const removeAbsoluteFromUrl = (url: any): string => {
  const domains = {
    fr: 'manomano\\.fr',
    gb: 'manomano\\.co\\.uk',
    de: 'manomano\\.de',
    it: 'manomano\\.it',
    es: 'manomano\\.es',
  };

  const prefix = isB2B() ? 'pro' : 'www';
  const domain = domains[getPlatform() as keyof typeof domains];

  const fullPattern = new RegExp(
    `^https?://${prefix}(\\.int|\\.stg)?\\.${domain}`,
  );

  const relativeUrl = url.replace(fullPattern, '');
  return relativeUrl;
};
