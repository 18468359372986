import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import { type ControlsProps } from '../../BasicMediaCarousel';
import { CarouselArrow } from '../CarouselArrow/CarouselArrow';
import { CarouselIndicators } from '../CarouselIndicators/CarouselIndicators';

import styles from './DefaultMediaCarouselControls.module.scss';

export interface ThemeProps {
  /**
   * display a small controls version of the carousel
   * /!\ only for dots, the page indicator has to be updated to work properly
   */
  smallControls?: boolean;
}

export interface DefaultMediaCarouselControlsProps
  extends ControlsProps,
    ThemeProps {
  /**
   * Always display the arrows on desktop. Only active if there are more than 1 element. false by default
   */
  alwaysShowButtonsOnDesktop?: boolean;
  /**
   * Always display the arrows on mobile. Only active if there are more than 1 element. false by default
   */
  alwaysShowButtonsOnMobile?: boolean;
  /**
   * When disableIndicators is true,
   * the dot indicators below the MediaCarousel are hidden
   */
  disableIndicators?: boolean;
  /**
   * Added an optional className for the indicator.
   */
  indicatorClassName?: string;
  /**
   * pass an icon to be displayed inside the page indicator
   */
  indicatorIcon?: FunctionComponent;
  /**
   * choose the position indicator between dots (default) or a page number.
   */
  indicatorType?: 'dots' | 'page';
}

export const DefaultMediaCarouselControls: FunctionComponent<
  DefaultMediaCarouselControlsProps
> = ({
  alwaysShowButtonsOnDesktop = false,
  alwaysShowButtonsOnMobile = false,
  disableIndicators = false,
  indicatorClassName,
  indicatorIcon: IndicatorIcon,
  indicatorType = 'dots',
  isNextDisabled,
  isPrevDisabled,
  numberOfSlides,
  onNextSlide,
  onPrevSlide,
  scrollState,
  smallControls = false,
}) => (
  <>
    <CarouselArrow
      direction="left"
      small={smallControls}
      onClick={onPrevSlide}
      disabled={isPrevDisabled}
      className={classnames(styles.carouselArrow, {
        [styles.alwaysShowButtonsOnDesktop]: alwaysShowButtonsOnDesktop,
        [styles.alwaysShowButtonsOnMobile]: alwaysShowButtonsOnMobile,
      })}
    />
    <CarouselArrow
      direction="right"
      small={smallControls}
      onClick={onNextSlide}
      disabled={isNextDisabled}
      className={classnames(styles.carouselArrow, {
        [styles.alwaysShowButtonsOnDesktop]: alwaysShowButtonsOnDesktop,
        [styles.alwaysShowButtonsOnMobile]: alwaysShowButtonsOnMobile,
        [styles.smallArrows]: smallControls,
      })}
    />
    {!disableIndicators && (
      <CarouselIndicators
        size={numberOfSlides}
        small={smallControls}
        activeIndex={scrollState.index}
        icon={IndicatorIcon}
        className={classnames(
          styles.carouselIndicators,
          smallControls && styles.smallControls,
          indicatorClassName,
        )}
        type={indicatorType}
      />
    )}
  </>
);
