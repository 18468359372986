import classnames from 'classnames';
import { type FunctionComponent, type PropsWithChildren } from 'react';

import { DotIndicator } from '@/core/tamagoshi/MediaCarousel/components/DotIndicator/DotIndicator';

import styles from './CarouselIndicators.module.scss';

export interface CarouselIndicatorsProps {
  activeIndex: number;
  className?: string;
  icon?: FunctionComponent<PropsWithChildren>;
  size: number;
  small?: boolean;
  type?: 'dots' | 'page';
}

export const CarouselIndicators: FunctionComponent<CarouselIndicatorsProps> = ({
  activeIndex,
  className,
  icon: Icon,
  size,
  small,
  type = 'dots',
}) =>
  type === 'page' ? (
    <div
      className={classnames(styles.pageIndicator, className)}
      data-testid="carouselIndicators"
    >
      {Icon && (
        <span className={styles.icon}>
          <Icon />
        </span>
      )}{' '}
      {activeIndex + 1} / {size}
    </div>
  ) : (
    <div className={className} data-testid="carouselIndicators">
      {Array.from({ length: size }, (_, index: number) => (
        <DotIndicator
          active={index === activeIndex}
          className={classnames(
            styles.dotIndicator,
            small && styles.smallIndicator,
          )}
          dataTestId={`dot-${index + 1}`}
          key={`indicator-${index}`}
          small={small}
        />
      ))}
    </div>
  );
