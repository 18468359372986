import type {
  BannerCampaign,
  BannerCampaignFromGqlApi,
} from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { removeAbsoluteFromUrl } from '@/productDiscovery/HeaderLeonidas/utils/removeAbsoluteFromUrl';

export const bannerCampaignGqlProcessor = (
  campaign: BannerCampaignFromGqlApi,
): BannerCampaign => ({
  id: campaign.id,
  link: {
    url: campaign.link?.url ? removeAbsoluteFromUrl(campaign.link.url) : null,
    target: campaign.link?.target || '_self',
  },
  sponsor: campaign.sponsor,
  advertiser: campaign.advertiser,
  pageType: campaign.pageType,
  trackingId: campaign.trackingId,
  image: {
    desktopUrl:
      campaign.image && campaign.image.desktopUrl
        ? campaign.image.desktopUrl
        : '',
    mobileUrl:
      campaign.image && campaign.image.mobileUrl
        ? campaign.image.mobileUrl
        : '',
    altText:
      campaign.image && campaign.image.altText ? campaign.image.altText : '',
  },
  name: campaign.name,
  ...(campaign.featureFlag && {
    featureFlag: campaign.featureFlag,
  }),
  ...(campaign.title && {
    title: campaign.title,
  }),
  ...(campaign.abTest && {
    abTest: campaign.abTest,
  }),
  ...(campaign.attributes &&
    campaign.attributes.claim && {
      claim: campaign.attributes.claim,
    }),
  ...(campaign.attributes &&
    campaign.attributes.operation_id && {
      commercialOperationId: campaign.attributes.operation_id,
    }),
});
