import classnames from 'classnames';
import {
  type FunctionComponent,
  type KeyboardEvent,
  type MouseEvent,
} from 'react';

import { Button } from '../../../Button/Button';
import { type Direction } from '../../../Carousel/components/Control/Control';
import { ArrowLeft as IconArrowLeft } from '../../../icons/ArrowLeft';
import { ArrowRight as IconArrowRight } from '../../../icons/ArrowRight';

import styles from './CarouselArrow.module.scss';

export interface CarouselArrowProps {
  className?: string;
  direction: Direction;
  disabled?: boolean;
  onClick: (event: KeyboardEvent | MouseEvent) => unknown;
  small?: boolean;
}

export const CarouselArrow: FunctionComponent<CarouselArrowProps> = ({
  className,
  direction,
  disabled = false,
  onClick,
  small,
}) => {
  const arrowProps = small
    ? {
        'data-testid': `small-icon-arrow-${direction}`,
        className: classnames(styles.rootIcon, { [styles.smallArrow]: small }),
      }
    : {};

  return (
    <Button
      shape="round"
      aria-label={direction}
      className={classnames(
        styles.carouselArrow,
        direction === 'left'
          ? styles.carouselArrowLeft
          : styles.carouselArrowRight,
        {
          [styles.smallArrowSpacing]: small,
          [styles.smallArrows]: small,
        },
        className,
      )}
      onClick={onClick}
      isDisabled={disabled}
      data-testid={direction}
      icon={
        direction === 'left' ? (
          <IconArrowLeft {...arrowProps} />
        ) : (
          <IconArrowRight {...arrowProps} />
        )
      }
    />
  );
};
