import Head from 'next/head';
import type { FunctionComponent } from 'react';

import { BannerCampaignAdvertiserLabel } from '@/domains/productDiscovery/CommercialAnimation/components/AdvertiserInformation/BannerCampaignAdvertiserLabel';
import type { BannerCampaign } from '@/domains/productDiscovery/CommercialAnimation/interfaces/bannerCampaign';
import { CampaignImage } from '@/productDiscovery/CommercialAnimation/components/CampaignImage/CampaignImage';

import styles from './CarouselCard.module.scss';

export interface CarouselCardProps {
  element: BannerCampaign;
  loading?: 'eager' | 'lazy';
  index?: number;
}

const SIZES: Record<
  'large' | 'medium' | 'small',
  Record<'hdpi' | 'ldpi', { height: number; width: number }>
> = {
  large: {
    hdpi: { height: 600, width: 1820 },
    ldpi: { height: 300, width: 910 },
  },
  medium: {
    hdpi: { height: 424, width: 1280 },
    ldpi: { height: 212, width: 640 },
  },
  small: {
    hdpi: { height: 412, width: 720 },
    ldpi: { height: 206, width: 360 },
  },
};

export const CarouselCard: FunctionComponent<CarouselCardProps> = ({
  element,
  loading = 'eager',
  index,
}) => {
  const { image, link, sponsor, advertiser, userSegments } = element;
  const { altText, desktopUrl, mobileUrl } = image;

  return (
    <div
      className={styles.carouselSlide}
      data-testid={`carouselCard-${altText}-${index}`}
    >
      {loading === 'eager' && (
        <Head>
          <link
            rel="preload"
            as="image"
            fetchpriority="high"
            imageSrcSet={`${desktopUrl}?w=${SIZES.large.ldpi.width}&h=${SIZES.large.ldpi.height}&fit=cover&format=webp 1x, ${desktopUrl}?w=${SIZES.large.hdpi.width}&h=${SIZES.large.hdpi.height}&fit=cover&format=webp 2x`}
            media="screen and (min-width: 1440px)"
            type="image/webp"
          />
          <link
            rel="preload"
            as="image"
            fetchpriority="high"
            imageSrcSet={`${mobileUrl}?w=${SIZES.medium.ldpi.width}&h=${SIZES.medium.ldpi.height}&fit=cover&format=webp 1x, ${mobileUrl}?w=${SIZES.medium.hdpi.width}&h=${SIZES.medium.hdpi.height}&fit=cover&format=webp 2x`}
            media="screen and (min-width: 1024px) and (max-width: 1439px)"
            type="image/webp"
          />
          <link
            rel="preload"
            as="image"
            fetchpriority="high"
            imageSrcSet={`${mobileUrl}?w=${SIZES.small.ldpi.width}&h=${SIZES.small.ldpi.height}&fit=cover&format=webp 1x, ${mobileUrl}?w=${SIZES.small.hdpi.width}&h=${SIZES.small.hdpi.height}&fit=cover&format=webp 2x`}
            media="screen and (max-width: 1023px)"
            type="image/webp"
          />
        </Head>
      )}
      {(sponsor || advertiser) && (
        <BannerCampaignAdvertiserLabel
          sponsor={sponsor}
          advertiser={advertiser}
          userSegments={userSegments}
          spaceSlug="main_slider"
        />
      )}
      <CampaignImage
        imageDesktopUrl={desktopUrl || null}
        imageMobileUrl={mobileUrl || null}
        altText={altText}
        sizes={SIZES}
        loading={loading}
        imgClassName={styles.carouselImg}
        dataTestId="HomepageMain"
        link={link}
        linkClassName={styles.carouselLink}
        type="slider"
        bannerCampaign={element}
        position={index || 0}
      />
    </div>
  );
};

CarouselCard.displayName = 'CarouselCard';
