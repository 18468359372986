import classnames from 'classnames';
import { type FunctionComponent } from 'react';

import styles from './DotIndicator.module.scss';

interface DotIndicatorProps {
  active: boolean;
  className?: string;
  dataTestId?: string;
  small?: boolean;
}

export const DotIndicator: FunctionComponent<DotIndicatorProps> = ({
  active,
  className,
  dataTestId,
  small,
}) => (
  <div
    className={classnames(
      styles.root,
      active && styles.active,
      small && styles.small,
      className,
    )}
    data-testid={dataTestId}
  />
);
