export const DISPLAY_CAMPAIGN_IMAGE = 'retention/DISPLAY_CAMPAIGN_IMAGE';

interface CampaignImageEvent {
  type: string;
  bannerCampaignId: string;
  bannerCampaignName: string;
  bannerCampaignLinkUrl: string;
  bannerCampaignPageType?: string;
  bannerCampaignTrackingId?: string;
  position: number;
  hasSponsoredLabel: boolean;
}

export interface DisplayCampaignImage {
  type: typeof DISPLAY_CAMPAIGN_IMAGE;
  data: CampaignImageEvent;
}

export const displayCampaignImage = (
  data: CampaignImageEvent,
): DisplayCampaignImage => ({
  type: DISPLAY_CAMPAIGN_IMAGE,
  data,
});
